import React from "react";
import SuccessIcon from '../assets/images/SuccessIcon.png'
import finleLogo2 from '../assets/logo/finlelogo2.png'

const SuccessPage = () => {
  return (
    <>
    <div style={{position: "relative", display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: 'column', padding: '1rem', gap: '2rem', overflow: 'hidden', height: '100vh'}}>
      <div>
    <img src={finleLogo2} alt="" style={{height: '3.5rem', objectFit: 'contain'}}/>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

      <img src={SuccessIcon} alt="" style={{height: "6rem", objectFit: "contain"}}/>
      <h3 style={{color: "#40c057"}}>Success</h3>
      <p style={{color: "#9B9A97"}}>E-Sign successful. You may close the tab now.</p>
      </div>
    <footer style={{position: "fixed", bottom: "0rem", width: '100%', backgroundColor: '#020438', color: 'white', padding: '1rem', textAlign: 'center'}}>
      Copyright © Finle
    </footer>
    </div>
    </>
  );
};

export default SuccessPage;
