/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */

import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import '../styles/DealerPage.css';
import { Link } from "react-router-dom";

const DealerPage = () => {
  return (
    <Container className="about-dealer-container">
      <Row>
        <Col>
          <Card className="dealer-card">
            <Card.Body>
            <h1>Become a FinLe Dealer</h1>
            <br/>
            <h5 className="">Why Partner with FinLe?</h5>
            <br/>
            <h5>Increased Profitability</h5>
            <p>
            By becoming a FinLe dealer, you gain access to a robust network of financing solutions that can help boost your sales. Our partnership with leading financial institutions like FexPrime Finance Pvt Ltd ensures that your customers have seamless access to financing options, making it easier for them to purchase vehicles from you.     
                 </p>
                 <h5>Comprehensive Support</h5>
            <p>
  
            We provide extensive training and support to our dealers. This includes marketing materials, sales strategies, and technical assistance to help you grow your business effectively.
                    </p>
                    <h5> Enhanced Customer Satisfaction</h5>
                    <p>With FinLe's easy and quick financing solutions, your customers can enjoy a hassle-free buying experience. This improves customer satisfaction and loyalty, leading to repeat business and referrals.</p>
                    <h5> Exclusive Dealer Benefits</h5>
                    <li>Access to special financing programs, promotional offers, and dealer incentives.
                    </li>
                    <li>Priority listing on our website, increasing your visibility to potential customers.</li>
                    <li>Dedicated account manager to assist you with all your needs.</li>
  <br/>
              <h5>How to Onboard as a FinLe Dealer</h5>
              <Card.Text>
              <b>Step 1: Initial Inquiry     </b>        
                 <ul>
                  <li>Contact our dealer onboarding team through our website or call us at [Contact Number]. We will provide you with all the necessary information and answer any questions you may have.</li>
                  
                </ul>
                <br/>
                <b>Step 2: Submit Required Documents

                </b>        
                <ul>
                 <li>Prepare and submit the following documents to initiate the onboarding process:
                 </li>
                 <ul>
                   <li>Letter of appointment (Letter of Intent)</li>
                   <li>Letter of appointment (Letter of Intent)</li>
                   <li>Certificate of registration and trade certificate</li>
                   <li>Quotation</li>
                   <li>Firm PAN card (RBH approval along with proprietor PAN card)</li>
                   <li>ID proof of owner/authorized signatory (Partner/director) (voter ID/PAN card/Aadhar card/bank passbook with photo/passport, etc.)</li>
                   <li>Address proof of owner (electricity bill/landline bill/trade certificate/post office passbook)</li>
                   <li>Bank details (Cancelled cheque, bank mandate form, latest bank statement/bank passbook)</li>
                   <li>All documents should be stamped and signed by the dealer</li>
                   <li>If a partnership firm, provide the partnership deed</li>
                   <li>Shop act license/Gumasta licence/Gram panchayat NOC</li>
                   <li>Email ID of the dealer</li>
                   <li>TIN no. – Not required if the firm is registered after 01.07.2017. GST is applicable</li>
                
                 </ul>
                 
               </ul>
               <br/>
               <b>Step 3: Verification and Approval</b>
               <li>Our team will review your submitted documents and verify your credentials. Once approved, you will receive a confirmation email along with your dealer login credentials.
               </li>
               
               <br/>
               <b>Step 4: Training and Onboarding</b>
               <li>Attend our dealer training sessions to understand our processes, financing options, and how to use the FinLe portal effectively. Our team will be available to support you throughout this process.</li>

               <br/>
               <b>Step 5: Start Selling with FinLe</b>
               <li>Begin offering FinLe financing solutions to your customers and enjoy the benefits of being a part of our growing network.</li>

              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Ready to Get Started?</h2>
          <p>
          Contact us today at <b>info@finle.in</b> or call <b>9303800246</b> to begin your journey as a FinLe dealer. Let's drive success together!      </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to='/register'>
            <Button variant="primary" className="contact-btn">
              Register Now 
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default DealerPage;
