import React from 'react'
import { Table } from 'react-bootstrap';
import "../styles/Loan.css"
import fexlogo from "../assets/logo/fex_logo.png"
const Loan = () => {
  return (
    <div className='main-loan'>
       
      <center><h2 style={{color:"#F37021"}}>3W Loan Product Details </h2></center>
      <br/>

      <div>
        <h5 className='heading-t'>Eligibility</h5>

        <ul>
          <li>Resident of India</li>
          <li>Age: 21 – 55 Years</li>
          <li>Valid Driving License</li>
          <li>Minimum Income: Rs. 12,000/-</li>
        </ul>
      </div>

      <h5 className='heading-t'>Documents Required
      </h5>
      <div >
        <ul>
          <li>KYC Documents: Aadhaar/ Passport/ Voter’s ID/Driving License/NREGA Job Card/Letter of National Population Register</li>
          <li>PAN Card</li>
          <li>Utility bill / Rent Agreement</li>
          <li>Income Proofs</li>
        </ul>
        <p>Note: The Company may seek any further additional information and documents from the applicant for processing the loan application.
        </p>

      </div>



      <div>
       
        <h5 className='heading-t'>Interest and Schedule of Charges</h5>
        <ul>
          <li>Interest rates - Ranging from 25% (Reducing Balance)</li>
        </ul>
        <br />
      </div>

      <div>
     
        <center><h2 style={{color:"#F37021"}}>SCHEDULE OF CHARGES</h2></center>
        <Table responsive="sm" border={1} className='loan-table'>
          <thead>
            <tr>
              <th>I. UPFRONT CHARGES</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Processing Fee</td>
              <td>Up to 6% + applicable taxes</td>
            </tr>
            <tr>
              <td>Stamp Duty</td>
              <td>Actual</td>

            </tr>
            <tr>
              <td>e-NACH / Mandate RegistrationCharges</td>
              <td>Rs.750 + applicable taxes</td>

            </tr>
            <tr>
              <td>Other Charges (details to be given)</td>
              <td>For any other charges, details shall be disclosed in the Sanction Letter & KFS</td>
            </tr>
            <tr>
              <td> <b>II. PENAL CHARGES </b></td></tr>
            <tr>
              <td>Late Payment Penalty / Charges</td>
              <td>
                24% p.a + applicable taxes</td>
            </tr>
            <tr>
              <td>NACH / e-mandate Bouncing Charges</td>
              <td>Rs. 700 per bounce + applicable taxes</td>
            </tr>
            <tr>
              <td>NACH / e-mandate registration failure charges</td>
              <td>
                Rs. 500 + applicable taxes
              </td>
            </tr>


            <tr> <td> <b>III. OTHER CHARGES</b></td></tr>
            <tr>
              <td>Mandate Swapping Charges</td>
              <td>Rs. 500 + applicable tax</td>
            </tr>
            <tr>
              <td>Pre-Payment Charges</td>
              <td>After 6 months from date of disbursal - 6% over outstanding principal amount of date of early closure + applicable taxes</td>
            </tr>
            <tr>
              <td></td>
              <td>Within 6 months from date of disbursal - 8% over outstanding principal amount of date of early closure + applicable taxes</td>
            </tr>
            <tr>
              <td>Physical Document Charges</td>
              <td>Rs. 250 + applicable taxes</td>
            </tr>
            <tr>
              <td>Loan Cancellation Charges</td>
              <td>
                1% of loan amount or Rs. 5000/- plus taxes whichever is higher Applicable with-in 15 days of loan disbursement</td>

            </tr>
            <tr>
              <td>Recovery/Legal expenses in case of default of repayment</td>
              <td>Actual**
                In case Borrower breach terms & conditions of the Loan Agreement or default in paying the instalment/dues committed. If any legal expenses/incidental expenses, incurred by FFPL for recovery, legal proceeding shall be paid by the borrower along with due LPP over same</td>
            </tr>
          </tbody>
        </Table>


        <br />
        <br />

      </div>
      <div>

        <h5 className='heading-t'>Our Lending Partner </h5>
        <a href='https://www.fexprime.com/e-rickshaw' target='blank' >
          <h5>Fexprime Finance Pvt. Ltd</h5>
          <br />
          <img className='img-logo' alt="error" src={fexlogo} />
        </a>
      </div>
    </div>
  )
}

export default Loan
