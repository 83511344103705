import React from 'react'
import FailureIcon from '../assets/images/FailureIcon.png'
import finleLogo2 from '../assets/logo/finlelogo2.png'


const FailurePage = () => {
  return (
    <>
    <div style={{position: "relative", height: '100vh', display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: 'column', padding: '1rem', gap: '2rem'}}>
      <div>
    <img src={finleLogo2} alt="" style={{height: '3.5rem', objectFit: 'contain'}}/>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

      <img src={FailureIcon} alt="" style={{height: "4rem", objectFit: "contain"}}/>
      <h3 style={{color: "red"}}>Failed</h3>
      <p style={{color: "#9B9A97"}}>E-Sign unsuccessful. You may close the tab now.</p>
      </div>
    <footer style={{position: "fixed", bottom: 0, width: '100%', backgroundColor: '#020438', color: 'white', padding: '1rem', textAlign: 'center'}}>
      Copyright © Finle
    </footer>
    </div>
    </>
  )
}

export default FailurePage
